<template>
<div>
  <h5 v-if="info">{{ $t('key_info') }}</h5>
  <ul v-if="info" class="list-unstyled">
    <li><strong>ПІБ</strong>: {{ info.Description }}</li>
    <li><strong>Описание формы собствености</strong>: {{ info.OwnershipFormDescription }}</li>
    <li><strong>Код ОКПО</strong>: {{ info.EDRPOU }}</li>
    <li><strong>Тип контрагента</strong>: {{ info.CounterpartyType }}</li>
  </ul>
</div>
</template>
<script>
export default {
  props: {
    contragentInfo: {
      required: true
    }
  },
  computed: {
    info () {
      return this.contragentInfo && this.contragentInfo[0]
    }
  }
}
</script>